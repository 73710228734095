import Analytics from '@hh.ru/analytics-js';
import urlParser from 'bloko/common/urlParser';

import Components from 'HHC/Components';

const ADFOX_SEPARATOR = ':';

const addYandexBanner = (element, params, isRetry = false) => {
    const date = new Date();
    const requestBannersUrl = urlParser(window.globalVars.bannersBatchUrl);
    const logicRegions = requestBannersUrl.params?.regions
        ? requestBannersUrl.params.regions[0].split(',').join(ADFOX_SEPARATOR) || ''
        : '';

    const mergedRegions = params.bannerCommonTargeting.puid1
        ? `${logicRegions}${ADFOX_SEPARATOR}${params.bannerCommonTargeting.puid1}`
        : logicRegions;

    const hideContainer = () => (element.style.display = 'none');

    window.yaContextCb.push(() => {
        window.Ya.adfoxCode.create({
            ownerId: params.ownerId,
            sequentialLoading: true,
            containerId: params.cId,
            params: {
                // Описание параметров https://wiki.hh.ru/display/ADV/DFP.+Key+Values
                ps: params.ps,
                pp: params.pp,
                p2: params.p2,
                puid4: date.getDay(),
                puid5: date.getHours(),
                puid6: window.globalVars.userType,
                puid7: window.location.host,
                puid8: window.location.pathname,
                puid9: window.globalVars.ads_sandbox,
                puid18: params.place,
                puid20: params.positionInfo,
                puid39: !params.shouldHideExternalBanners,
                ...params.bannerCommonTargeting,
                ...params.bannerAdTargeting,
                puid1: mergedRegions,
            },
            lazyLoad: {
                fetchMargin: 200,
                mobileScaling: 2,
            },
            onLoad: (data) => {
                if (data) {
                    params.callback();
                    element.dataset.adfoxRequested = 'true';
                }
            },
            onStub: () => {
                element.dataset.adfoxRequested = 'true';
                hideContainer();
            },
            onError: (error) => {
                element.dataset.adfoxRequested = 'true';
                Analytics.sendHHEvent('adfox_on_error', {
                    bannerId: params.bannerId,
                    pathname: window.location.pathname,
                    isRetry,
                    error: JSON.stringify(error),
                });
                if (!isRetry) {
                    addYandexBanner(element, params, true);
                } else {
                    hideContainer();
                }
            },
        });
    });
};

export default Components.build({
    create(element, params) {
        window.yaContextCb = window.yaContextCb || [];
        addYandexBanner(element, params);
    },
});
