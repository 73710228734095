export interface PostFormData {
    [key: string]: unknown | unknown[];
}

const itemIsArray = (item: unknown): item is unknown[] => {
    return Array.isArray(item);
};

const convertItem = (item: unknown): string | Blob => {
    return item instanceof Blob ? item : String(item);
};

export default function prepareFormData(data: PostFormData | FormData): FormData {
    if (data instanceof FormData) {
        return data;
    }

    const formData = new FormData();
    Object.keys(data).forEach((key) => {
        const dataItem = data[key];
        if (itemIsArray(dataItem)) {
            dataItem.forEach((arrayItem) => {
                if (arrayItem !== undefined) {
                    formData.append(String(key), convertItem(arrayItem));
                }
            });
        } else if (dataItem !== undefined) {
            formData.append(String(key), convertItem(dataItem));
        }
    });

    return formData;
}
