import { updateUrl } from 'Modules/url';
import type { VacancyOfTheDayLocation, WorkInCompanyLocation } from 'lux/utils/sendAdvSpyAnalytics';

import { VACANCY_OF_THE_DAY_UTMS } from 'lux/components/VacanciesOfTheDay/const';

interface VacancyShort {
    click?: string;
    links: {
        desktop: string;
    };
}

export const addLocationToLink = (link: string, location: VacancyOfTheDayLocation | WorkInCompanyLocation): string =>
    `${link}&location=${location}`;

export const getVacancyLink = (
    vacancy: VacancyShort,
    location: VacancyOfTheDayLocation | WorkInCompanyLocation,
    isRecommended = false
): string => {
    const link = vacancy.click || vacancy.links.desktop;
    if (isRecommended) {
        return link;
    }
    return addLocationToLink(link, location);
};

export const createSearchVacancyOfTheDayClickUrl = (url: string, isVacancyOfTheDay?: boolean): string =>
    isVacancyOfTheDay ? updateUrl(url, VACANCY_OF_THE_DAY_UTMS) : url;
