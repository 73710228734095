import axios, { AxiosError } from 'axios';
import { Merge } from 'type-fest';

import { RequestWithParamsWithDisableBrowserCache } from 'lux/modules/fetcher';

const ACCOUNT_LOGIN_REQUIRED_ERROR = 'account_login_required';

interface ErrorWithStringPayload {
    error: string;
}
type ErrorWithString = AxiosError<ErrorWithStringPayload>;

const isAxiosErrorWithString = (error: AxiosError): error is ErrorWithString => {
    return (
        !!error.response?.data &&
        typeof error.response?.data === 'object' &&
        typeof (error.response?.data as ErrorWithStringPayload).error === 'string'
    );
};

export const isAccountLoginRequiredError = (error: AxiosError): boolean => {
    return (
        error.response?.status === 403 &&
        isAxiosErrorWithString(error) &&
        error.response?.data?.error === ACCOUNT_LOGIN_REQUIRED_ERROR
    );
};

const AccountLoginInterceptor = (
    error: Merge<AxiosError, RequestWithParamsWithDisableBrowserCache>
): Promise<never> => {
    if (isAccountLoginRequiredError(error)) {
        const config = { ...error.config };
        delete config.params.disableBrowserCache;
        window.location.assign(axios.getUri(config));
    }

    return Promise.reject(error);
};

export default AccountLoginInterceptor;
